<template>
    <section class="efficiency-restaurant" v-if="status === 'success'">
        <h2 class="sr-only">Данные отчета "Доходы ресторана"</h2>
        <div class="efficiency-restaurant__base" v-if="modeId === 'base'">
            <div class="efficiency-restaurant__box efficiency-restaurant__box_charts box">
                <div class="efficiency-restaurant__chart-wrapper efficiency-restaurant__chart-wrapper_first-of-three">
                    <figure class="efficiency-restaurant__chart">
                        <figcaption class="efficiency-restaurant__chart-title h2">Гостей на заказ</figcaption>
                        <highcharts class="efficiency-restaurant__chart-graph" :options="chartOptions('avg_guests_in_order')" />
                    </figure>
                </div>
                <div class="efficiency-restaurant__chart-wrapper efficiency-restaurant__chart-wrapper_second-of-three">
                    <figure class="efficiency-restaurant__chart">
                        <figcaption class="efficiency-restaurant__chart-title h2">Чек на гостя</figcaption>
                        <highcharts class="efficiency-restaurant__chart-graph" :options="chartOptions('avg_check_per_guest')" />
                    </figure>
                </div>
                <div class="efficiency-restaurant__chart-wrapper efficiency-restaurant__chart-wrapper_third-of-three">
                    <figure class="efficiency-restaurant__chart">
                        <figcaption class="efficiency-restaurant__chart-title h2">Блюд на гостя</figcaption>
                        <highcharts class="efficiency-restaurant__chart-graph" :options="chartOptions('avg_dish_per_guest')" />
                    </figure>
                </div>
                <div class="efficiency-restaurant__chart-wrapper efficiency-restaurant__chart-wrapper_first-of-three">
                    <figure class="efficiency-restaurant__chart">
                        <figcaption class="efficiency-restaurant__chart-title h2">Цена блюда</figcaption>
                        <highcharts class="efficiency-restaurant__chart-graph" :options="chartOptions('avg_dish_price')" />
                    </figure>
                </div>
                <div class="efficiency-restaurant__chart-wrapper efficiency-restaurant__chart-wrapper_second-of-three">
                    <figure class="efficiency-restaurant__chart">
                        <figcaption class="efficiency-restaurant__chart-title h2">Заказов на курьера</figcaption>
                        <highcharts class="efficiency-restaurant__chart-graph" :options="chartOptions('avg_orders_per_courier')" />
                    </figure>
                </div>
            </div>
            <div class="efficiency-restaurant__box efficiency-restaurant__box_table box">
                <table class="efficiency-restaurant__table">
                    <colgroup>
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_title">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_income">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_orders-count">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_average-bill">
                    </colgroup>
                    <thead>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_head">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head" scope="col">&nbsp;</td>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Гостей на заказ</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Чек на гостя</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Блюд на гостя</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Цена блюда</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Заказов на курьера</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                Доставка
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.delivery.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.delivery.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.delivery.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.delivery.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.delivery.avg_orders_per_courier | floatFormat}}
                            </td>
                        </tr>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                Зал
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.hall.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.hall.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.hall.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.hall.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.hall.avg_orders_per_courier | floatFormat}}
                            </td>
                        </tr>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                С собой
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.take.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.take.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.take.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.take.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.take.avg_orders_per_courier | floatFormat}}
                            </td>
                        </tr>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                Самовынос
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.pickup.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.pickup.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.pickup.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.pickup.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.pickup.avg_orders_per_courier | floatFormat}}
                            </td>
                        </tr>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                Итого
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.total.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.total.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.total.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.total.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{reportRestaurant.total.avg_orders_per_courier | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="efficiency-restaurant__compare-other-period" v-else-if="modeId === 'compareOtherPeriod' && compareRestaurant">
            <div class="efficiency-restaurant__box efficiency-restaurant__box_table box">
                <table class="efficiency-restaurant__table">
                    <caption class="efficiency-restaurant__table-caption">Заказов в час</caption>
                    <colgroup>
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                    </colgroup>
                    <thead>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_head">
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Доставка</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Зал</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">С собой</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Самовынос</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Итого</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.delivery.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.delivery.avg_guests_in_order !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.delivery.avg_guests_in_order"
                                    :compareValue="compareRestaurant.delivery.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.hall.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.hall.avg_guests_in_order !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.hall.avg_guests_in_order"
                                    :compareValue="compareRestaurant.hall.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.take.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.take.avg_guests_in_order !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.take.avg_guests_in_order"
                                    :compareValue="compareRestaurant.take.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.pickup.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.pickup.avg_guests_in_order !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.pickup.avg_guests_in_order"
                                    :compareValue="compareRestaurant.pickup.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.total.avg_guests_in_order | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.total.avg_guests_in_order !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.total.avg_guests_in_order"
                                    :compareValue="compareRestaurant.total.avg_guests_in_order"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body"
                            v-if="compareRestaurant"
                        >
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.delivery.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.hall.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.take.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.pickup.avg_guests_in_order | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.total.avg_guests_in_order | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="efficiency-restaurant__box efficiency-restaurant__box_table box">
                <table class="efficiency-restaurant__table">
                    <caption class="efficiency-restaurant__table-caption">Гостей в час</caption>
                    <colgroup>
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                    </colgroup>
                    <thead>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_head">
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Доставка</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Зал</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">С собой</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Самовынос</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Итого</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.delivery.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.delivery.avg_check_per_guest !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.delivery.avg_check_per_guest"
                                    :compareValue="compareRestaurant.delivery.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.hall.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.hall.avg_check_per_guest !== 0"
                                     class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.hall.avg_check_per_guest"
                                    :compareValue="compareRestaurant.hall.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.take.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.take.avg_check_per_guest !== 0"
                                     class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.take.avg_check_per_guest"
                                    :compareValue="compareRestaurant.take.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.pickup.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.pickup.avg_check_per_guest !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.pickup.avg_check_per_guest"
                                    :compareValue="compareRestaurant.pickup.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.total.avg_check_per_guest | currencyIntFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.total.avg_check_per_guest !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.total.avg_check_per_guest"
                                    :compareValue="compareRestaurant.total.avg_check_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body"
                            v-if="compareRestaurant"
                        >
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.delivery.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.hall.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.take.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.pickup.avg_check_per_guest | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.total.avg_check_per_guest | currencyIntFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="efficiency-restaurant__box efficiency-restaurant__box_table box">
                <table class="efficiency-restaurant__table">
                    <caption class="efficiency-restaurant__table-caption">Блюд в час</caption>
                    <colgroup>
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                    </colgroup>
                    <thead>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_head">
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Доставка</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Зал</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">С собой</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Самовынос</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Итого</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.delivery.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.delivery.avg_dish_per_guest !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.delivery.avg_dish_per_guest"
                                    :compareValue="compareRestaurant.delivery.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.hall.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.hall.avg_dish_per_guest !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.hall.avg_dish_per_guest"
                                    :compareValue="compareRestaurant.hall.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.take.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.take.avg_dish_per_guest !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.take.avg_dish_per_guest"
                                    :compareValue="compareRestaurant.take.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.pickup.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.pickup.avg_dish_per_guest !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.pickup.avg_dish_per_guest"
                                    :compareValue="compareRestaurant.pickup.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.total.avg_dish_per_guest | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.total.avg_dish_per_guest !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.total.avg_dish_per_guest"
                                    :compareValue="compareRestaurant.total.avg_dish_per_guest"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body"
                            v-if="compareRestaurant"
                        >
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.delivery.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.hall.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.take.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.pickup.avg_dish_per_guest | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.total.avg_dish_per_guest | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="efficiency-restaurant__box efficiency-restaurant__box_table box">
                <table class="efficiency-restaurant__table">
                    <caption class="efficiency-restaurant__table-caption">Цена блюда</caption>
                    <colgroup>
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                    </colgroup>
                    <thead>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_head">
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Доставка</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Зал</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">С собой</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Самовынос</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Итого</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.delivery.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.delivery.avg_dish_price !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.delivery.avg_dish_price"
                                    :compareValue="compareRestaurant.delivery.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.hall.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.hall.avg_dish_price !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.hall.avg_dish_price"
                                    :compareValue="compareRestaurant.hall.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.take.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.take.avg_dish_price !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.take.avg_dish_price"
                                    :compareValue="compareRestaurant.take.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.pickup.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.pickup.avg_dish_price !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.pickup.avg_dish_price"
                                    :compareValue="compareRestaurant.pickup.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.total.avg_dish_price | currencyIntFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.total.avg_dish_price !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.total.avg_dish_price"
                                    :compareValue="compareRestaurant.total.avg_dish_price"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body"
                            v-if="compareRestaurant"
                        >
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.delivery.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.hall.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.take.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.pickup.avg_dish_price | currencyIntFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.total.avg_dish_price | currencyIntFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="efficiency-restaurant__box efficiency-restaurant__box_table box">
                <table class="efficiency-restaurant__table">
                    <caption class="efficiency-restaurant__table-caption">Заказов на курьера</caption>
                    <colgroup>
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                        <col class="efficiency-restaurant__table-column efficiency-restaurant__table-column_compare">
                    </colgroup>
                    <thead>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_head">
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head" scope="col">Источник</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Доставка</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Зал</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">С собой</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Самовынос</th>
                            <th class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_head efficiency-restaurant__table-cell_tar" scope="col">Итого</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body">
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.delivery.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.delivery.avg_orders_per_courier !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.delivery.avg_orders_per_courier"
                                    :compareValue="compareRestaurant.delivery.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.hall.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.hall.avg_orders_per_courier !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.hall.avg_orders_per_courier"
                                    :compareValue="compareRestaurant.hall.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.take.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.take.avg_orders_per_courier !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.take.avg_orders_per_courier"
                                    :compareValue="compareRestaurant.take.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.pickup.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.pickup.avg_orders_per_courier !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.pickup.avg_orders_per_courier"
                                    :compareValue="compareRestaurant.pickup.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                <span class="efficiency-restaurant__table-value">
                                    {{reportRestaurant.total.avg_orders_per_courier | floatFormat}}
                                </span>
                                <difference v-if="compareRestaurant && compareRestaurant.total.avg_orders_per_courier !== 0"
                                    class="efficiency-restaurant__table-value"
                                    :primaryValue="reportRestaurant.total.avg_orders_per_courier"
                                    :compareValue="compareRestaurant.total.avg_orders_per_courier"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                        </tr>
                        <tr class="efficiency-restaurant__table-row efficiency-restaurant__table-row_body"
                            v-if="compareRestaurant"
                        >
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.delivery.avg_orders_per_courier | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.hall.avg_orders_per_courier | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.take.avg_orders_per_courier | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.pickup.avg_orders_per_courier | floatFormat}}
                            </td>
                            <td class="efficiency-restaurant__table-cell efficiency-restaurant__table-cell_body efficiency-restaurant__table-cell_tar">
                                {{compareRestaurant.total.avg_orders_per_courier | floatFormat}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="efficiency-restaurant__box efficiency-restaurant__box_charts box">
                <div class="efficiency-restaurant__chart-wrapper efficiency-restaurant__chart-wrapper_first-of-three">
                    <figure class="efficiency-restaurant__chart">
                        <figcaption class="efficiency-restaurant__chart-title h2">Гостей на заказ</figcaption>
                        <highcharts class="efficiency-restaurant__chart-graph" :options="chartOptions('avg_guests_in_order')" />
                    </figure>
                </div>
                <div class="efficiency-restaurant__chart-wrapper efficiency-restaurant__chart-wrapper_second-of-three">
                    <figure class="efficiency-restaurant__chart">
                        <figcaption class="efficiency-restaurant__chart-title h2">Чек на гостя</figcaption>
                        <highcharts class="efficiency-restaurant__chart-graph" :options="chartOptions('avg_check_per_guest')" />
                    </figure>
                </div>
                <div class="efficiency-restaurant__chart-wrapper efficiency-restaurant__chart-wrapper_third-of-three">
                    <figure class="efficiency-restaurant__chart">
                        <figcaption class="efficiency-restaurant__chart-title h2">Блюд на гостя</figcaption>
                        <highcharts class="efficiency-restaurant__chart-graph" :options="chartOptions('avg_dish_per_guest')" />
                    </figure>
                </div>
                <div class="efficiency-restaurant__chart-wrapper efficiency-restaurant__chart-wrapper_first-of-three">
                    <figure class="efficiency-restaurant__chart">
                        <figcaption class="efficiency-restaurant__chart-title h2">Цена блюда</figcaption>
                        <highcharts class="efficiency-restaurant__chart-graph" :options="chartOptions('avg_dish_price')" />
                    </figure>
                </div>
                <div class="efficiency-restaurant__chart-wrapper efficiency-restaurant__chart-wrapper_second-of-three">
                    <figure class="efficiency-restaurant__chart">
                        <figcaption class="efficiency-restaurant__chart-title h2">Заказов на курьера</figcaption>
                        <highcharts class="efficiency-restaurant__chart-graph" :options="chartOptions('avg_orders_per_courier')" />
                    </figure>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import { isDaterangeSet, daterangeText, } from "@/helpers/daterange";
    import * as filters from "@/helpers/filters";
    import Difference from "@/components/Difference";

    let totalTitle = "Итоговая эффективность по всем ресторанам";

    export default {
        name: "EfficiencyRestaurant",
        props: {
            id: {
                type: String,
                required: true
            },
            status: {
                type: String,
                required: true
            },
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                required: true
            },
            reportEfficiency: {
                required: true
            },
            compareEfficiency: {
                required: true
            },
            modes: {
                type: Array,
                required: true
            },
            modeId: {
                type: String,
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            isShowRestaurantsFilter: {
                type: Boolean,
                required: true
            },
            userRestaurants: {
                required: true
            }
        },
        components: {
            Difference
        },
        computed: {
            ...mapState({
                title: state => state.page.title
            }),
            reportRestaurant() {
                if (this.id === "total") {
                    return this.reportEfficiency?.total;
                }

                return this.reportEfficiency?.restaurants?.find(({ id }) => id === this.id);
            },
            compareRestaurant() {
                if (isDaterangeSet(this.compareDaterange)) {
                    if (this.id === "total") {
                        return this.compareEfficiency?.total;
                    }

                    return this.compareEfficiency?.restaurants?.find(({ id }) => id === this.id);
                }
            },
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "toggleReportTotalLink",
                "toggleRestaurantsFilter"
            ]),
            chartOptions(parameter) {
                let options = {
                    chart: {
                        type: "column"
                    },
                    colors: ["#eb4f3b", "#f4755c", "#fb9780", "#ffb7a5", "#e0121a"],
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: [daterangeText(this.reportDaterange)],
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: null
                        },
                        labels: {
                            overflow: "justify"
                        }
                    },
                    tooltip: {
                        pointFormatter() {
                            return `${this.series.name}: ${filters.floatFormat(this.y)}`;
                        }
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                formatter() {
                                    return filters.floatFormat(this.y);
                                }
                            }
                        }
                    },
                    legend: {
                        align: "center",
                        verticalAlign: "bottom",
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: "Доставка",
                        data: [this.reportRestaurant.delivery?.[parameter] || null]
                    }, {
                        name: "Зал",
                        data: [this.reportRestaurant.hall?.[parameter] || null]
                    }, {
                        name: "С собой",
                        data: [this.reportRestaurant.take?.[parameter] || null]
                    }, {
                        name: "Самовынос",
                        data: [this.reportRestaurant.pickup?.[parameter] || null]
                    }, {
                        name: "Итого",
                        data: [this.reportRestaurant.total?.[parameter] || null]
                    }]
                };

                if (this.modeId === "compareOtherPeriod" && this.compareRestaurant) {
                    options.xAxis.categories.push(daterangeText(this.compareDaterange));
                    options.series[0].data.push(this.compareRestaurant.delivery?.[parameter] || null);
                    options.series[1].data.push(this.compareRestaurant.hall?.[parameter] || null);
                    options.series[2].data.push(this.compareRestaurant.take?.[parameter] || null);
                    options.series[3].data.push(this.compareRestaurant.pickup?.[parameter] || null);
                    options.series[4].data.push(this.compareRestaurant.total?.[parameter] || null);
                }

                return options;
            }
        },
        filters: {
            daterangeText
        },
        created() {
            if (this.id !== "total" && !this.userRestaurants?.ids?.includes(this.id)) {
                this.$router.push({
                    name: "Error401",
                    params: {
                        requestedResource: `Ресторан ${this.id}`
                    }
                });
            } else {

                if (this.userRestaurants?.ids?.length > 1 && !this.isReportTotalLinkVisible) {
                    this.toggleReportTotalLink(true);
                }

                if (this.id === "total" && this.title !== totalTitle) {
                    this.setTitle(totalTitle);

                    this.toggleRestaurantsFilter(true);
                } else {
                    let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                    if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                        this.setTitle(`${this.title} "${restaurantTitle}"`);
                    }

                    this.toggleRestaurantsFilter(false);
                }
            }
        }
    }
</script>

<style lang="scss">
    .efficiency-restaurant__box {
        padding: 16px;

        & + & {
            margin-top: 15px;
        }

        &_charts {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;

            @include desktop {
                grid-template-columns: repeat(6, 1fr);
            }
        }

        &_table {
            padding-top: 8px;
            overflow-x: auto;
        }
    }
    .efficiency-restaurant__table {
        width: 100%;
    }
    .efficiency-restaurant__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .efficiency-restaurant__table-column {
        &_compare {
            width: 16.66%;
        }

        &_dynamic {
            width: 16.66%;
        }
    }
    .efficiency-restaurant__table-row {
        &_body {
            &:hover {
                background-color: $gray-form;
            }
        }
    }
    .efficiency-restaurant__table-cell {
        font-size: 20px;

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_compare {
            font-size: 16px;
            padding: 8px 4px;
            white-space: nowrap;
        }

        &_body {
            padding: 16px 8px;
        }

        &_title {
            font-weight: bold;
        }

        &_centered {
            text-align: center;
        }

        &_tar {
            text-align: right;
        }
    }
    .efficiency-restaurant__table-value {
        display: block;

        & + & {
            margin-left: 5px;
        }
    }
    .efficiency-restaurant__chart-wrapper {
        &_full-width {
            @include desktop {
                grid-column: 1/-1;
            }
        }

        &_first-of-three {
            @include desktop {
                grid-column: 1/3;
            }
        }

        &_second-of-three {
            @include desktop {
                grid-column: 3/5;
            }
        }

        &_third-of-three {
            @include desktop {
                grid-column: 5/7;
            }
        }
    }
    .efficiency-restaurant__chart-title {
        margin-bottom: 10px;
    }
</style>
